import React from 'react';
import { AppBar, Toolbar, Typography, Grid, useMediaQuery } from '@mui/material';

const Header = ({ title, subtitle }) => {
  const isMobile = useMediaQuery('(max-width:600px)'); 

  return (
    <AppBar position="static" sx={{ backgroundColor: '#c9b19d', padding: "2vh", marginTop: "6vh" }}>
      <Toolbar>
        <Grid 
          container 
          alignItems="center" 
          justifyContent={isMobile ? 'center' : 'flex-start'} 
        >
          <Grid item xs={12} md={6}>
            <Typography
              variant={isMobile ? 'h4' : 'h2'}  
              color='#000'
              fontWeight='bolder'
              textAlign={isMobile ? 'center' : 'center'} 
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant={isMobile ? 'h6' : 'h5'}  
              color='#755d4a'
              textAlign={isMobile ? 'center' : 'left'}  
              marginTop={isMobile ? '1vh' : '0'}  
            >
              {subtitle}
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
