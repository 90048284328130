import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = ({ footerText }) => {
  return (
    <Box sx={{ p: 3, mt: 4, textAlign: 'center' }}>
      <Typography variant="body1" display="block" color='black'>
        {footerText}
      </Typography>
    </Box>
  );
};

export default Footer;
