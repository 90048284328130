import React, { useState, useRef } from 'react';
import { Container, Typography, Grid, Box, Button, useMediaQuery, TextField } from '@mui/material';
import Header from './components/Header';
import Footer from './components/Footer';

import firstImage from './assets/images/product1.png';
import icon1 from './assets/images/icon1.png';
import icon2 from './assets/images/icon2.png';
import icon3 from './assets/images/icon3.png';
import icon4 from './assets/images/icon4.png';
import iconBg from './assets/images/icon_bg.png'; 
import section3Img from './assets/images/pipirkasrez.png';

import emailjs from 'emailjs-com';


function App() {
  const isMobile = useMediaQuery('(max-width:600px)'); 
  const naruciRef = useRef(null);

  const scrollToNaruci = () => {
    if (naruciRef.current) {
      naruciRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [errors, setErrors] = useState({
    ime: '',
    brojTelefona: ''
  });

  const [formData, setFormData] = useState({
    ime: '',
    brojTelefona: '',
    email: '',
    adresa: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Check if 'ime' (name) is empty
    if (!formData.ime) {
      newErrors.ime = 'Ime je obavezno.';
      isValid = false;
    }

    // Check if 'brojTelefona' (phone number) is empty
    // Check if 'brojTelefona' (phone number) starts with '0' and has at least 7 digits
    const phoneRegex = /^0\d{6,}$/;
    if (!formData.brojTelefona) {
      newErrors.brojTelefona = 'Broj telefona je obavezan.';
      isValid = false;
    } else if (!phoneRegex.test(formData.brojTelefona)) {
      newErrors.brojTelefona = 'Unesite ispravan broj telefona';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Sprečava osvežavanje stranice
    // Basic validation: Check if 'ime' and 'brojTelefona' are filled in
    // Validate the form before submission
    if (!validateForm()) {
      return;
    }
    emailjs
      .send(
        'service_mn8rxnd', 
        'template_jbpzbgn', 
        {
          ime: formData.ime,
          prezime: "",
          brojTelefona: formData.brojTelefona,
          email: formData.email,
          adresa: formData.adresa
        },
        '_NLJGds4NTF4fKzZa' 
      )
      .then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          setFormData({
            ime: '',
            prezime: '',
            brojTelefona: '',
            email: '',
            adresa: ''
          });
        },
        (error) => {
          console.log('Failed to send email:', error.text);
        }
      );
  };

  const section1Content = {
    title: "ŽELIS LI DA DOBIJES VIŠE?",
    body: "Maksimum zadovoljstva sa KAMAGRA GELOM",
    subtitle: "Kupite odmah sa popustom 30%"
  };


  const section2Content = {
    title: "žene obožavaju OVO:",
    body: "Anketa je obuhvatila 1863 žene uzrasta od 18 do 54 godina.",
  };

  const section3Content = {
    title: "Dajte ženama ono što žele s KAMAGRA GELOM",
    subtitle1: "Kamagra gel, obogaćen prirodnim biljnim ekstraktima, efikasno uklanja erektilnu disfunkciju kod muškaraca svih uzrasta i na bilo kojem stadijumu.",
    subtitle2: "Aktivni sastojci u Kamagra gelu poboljšavaju snabdevanje krvlju tkiva, stimulišu proizvodnju energije, pojačavaju seksualnu želju i povećavaju spermatogenezu. Ovaj moćni gel obezbeđuje dugotrajnu i snažnu erekciju, pružajući vam maksimalnu podršku za postizanje i održavanje seksualne vitalnosti."
  };

  const section5Content = {
    title: "Poručite odmah!",
    subtitle1: "Otkrijte svoju pravu strast!",
    subtitle2: "Bilo da ste za stolom, u autu, u bioskopu ili kod kuće u krevetu – Kamagra gel je tu da podrži vašu snagu. Tvoja partnerka će reći:",
    subtitle3: "HOĆU JOŠ!",
  };

  const footerContent = "© 2024 My Page Builder";

  return (
    <>
      <Header title="POTENTO" subtitle="TVRD, JAK, NEODOLJIV" />

      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container spacing={0} sx={{ mt: 4 }}>
          <Grid item xs={12} md={6} sx={{ marginLeft: isMobile ? "0" : "10vh" }}>
            <Typography 
              variant={isMobile ? "h5" : "h4"} 
              gutterBottom 
              fontWeight="bolder" 
              color='#4a4644'
              sx={{ marginTop: isMobile ? 0 : '20vh' }} 
            >
              {section1Content.title}
            </Typography>
            <Typography
              variant={isMobile ? "body1" : "h5"} 
              color='#4a4644'
              fontWeight="bold"  
            >
              {section1Content.body}
            </Typography>
            <Typography
              variant={isMobile ? "h6" : "h5"}
              color='#373a3c'
              fontWeight="light"
              mt={isMobile ? "10px" : 20}
            > 
              {section1Content.subtitle}
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box
              component="img"
              sx={{ width: '100%', height: 'auto', marginLeft: isMobile ? 0 : "60px" }}
              src={firstImage}
              alt="FirstImage"
            />

            <Box sx={{ mt: 0, textAlign: 'center' }}>
              <Typography variant="h5" sx={{ textDecoration: 'line-through', color: "#857b73" }}>
                1800 RSD
              </Typography>
              <Typography variant="h4" color="red">
                1260 RSD
              </Typography>
              <Typography variant="body2" color="black" sx={{ mt: 1 }}>
                *Rezultati se mogu razlikovati u zavisnosti od individualnih karakteristika organizma.
              </Typography>
              <Button variant="contained" color="primary" sx={{ mt: 2, backgroundColor: '#a64205', color: '#fff', padding: '10px 20px' }}  onClick={scrollToNaruci}>
                NARUCI ODMAH
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="100%" sx={{ marginTop: "4vh", padding: isMobile ? "100px" : "150px", backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
        <Typography
          variant={isMobile ? "h6" : "h3"}
          textTransform='uppercase'
          color="#fff"
          fontWeight="bolder"
          textAlign="center"
          sx={{ marginBottom: "10px"}} 
        >
          {section2Content.title}
        </Typography>

        <Grid container spacing={5} justifyContent="center" marginTop="35px">
          <Grid item xs={12} sm={3} textAlign="center">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                backgroundImage: `url(${iconBg})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain', 
                width: '100%',
                height: '200px', 
                borderRadius: '8px',
              }}
            >
              <Box
                component="img"
                sx={{ width: '25%', height: 'auto', position: 'relative', zIndex: 1, padding: 5, mt: 8 }}
                src={icon1}
                alt="icon1"
              />
              <Typography variant="body1" color="white" sx={{ mt: 5, zIndex: 1 }}>
                Dužina do 2 sata
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={3} textAlign="center">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                backgroundImage: `url(${iconBg})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain', 
                width: '100%',
                height: '200px', 
                borderRadius: '8px',
              }}
            >
              <Box
                component="img"
                sx={{ width: '25%', height: 'auto', position: 'relative', zIndex: 1, padding: 5, mt: 7 }}
                src={icon2}
                alt="icon2"
              />
              <Typography variant="body1" color="white" sx={{ mt: 5, zIndex: 1 }}>
                Mnogobrojni zadovoljni
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={3} textAlign="center">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                backgroundImage: `url(${iconBg})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain', 
                width: '100%',
                height: '200px', 
                borderRadius: '8px',
              }}
            >
              <Box
                component="img"
                sx={{ width: '25%', height: 'auto', position: 'relative', zIndex: 1, padding: 5, mt: 9 }}
                src={icon3}
                alt="icon3"
              />
              <Typography variant="body1" color="white" sx={{ mt: 5, zIndex: 1 }}>
                Kratke pauze do 10 minuta
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={3} textAlign="center">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                backgroundImage: `url(${iconBg})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain', 
                width: '100%',
                height: '200px', 
                borderRadius: '8px',
              }}
            >
              <Box
                component="img"
                sx={{ width: '25%', height: 'auto', position: 'relative', zIndex: 1, padding: 5, mt: 9 }}
                src={icon4}
                alt="icon4"
              />
              <Typography variant="body1" color="white" sx={{ mt: 5, zIndex: 1 }}>
                Mnogo energije
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="100%" sx={{ marginTop: "0", padding: isMobile ? "90px" : "150px", background: "rgba(255,255,255, 0.3)"}}>
        <Typography
          variant={isMobile ? "h5" : "h3"}
          textTransform='uppercase'
          color="#a64205"
          fontWeight="bolder"
          textAlign={isMobile ? "left" : "center"}
          sx={{ marginLeft: isMobile ? "0" : "5vh" }} 
        >
          {section3Content.title}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
            <Box
              sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }} 
            >
              <Box
                component="img"
                sx={{
                  width: { xs: '100%', sm: '380px' }, 
                  height: { xs: 'auto', sm: '400px' }, 
                  ml: { xs: 0, sm: 15 }, 
                  mb: { xs: 2, sm: 0 },
                  marginTop: "55px"  
                }}  
                src={firstImage}
                alt="First Image"
              />
              <Box
                component="img"
                sx={{
                  width: { xs: '100%', sm: '220px' },  
                  height: { xs: 'auto', sm: '200px' },  
                  mt: { xs: 2, sm: 25 },  
                  mr: { xs: 0, sm: 5 }   
                }} 
                src={section3Img}
                alt="Section Image"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ marginTop: { xs: 5, sm: 10 } }}>
            <Typography
              variant={isMobile ? "h6" : "h5"}
              color="#000"
              fontWeight="lighter" 
              sx={{ marginBottom: "20px", fontSize: { xs: '1rem', sm: '1.5rem' } }} 
            >
              {section3Content.subtitle1} 
            </Typography>
            <Typography
              variant={isMobile ? "h6" : "h5"}
              color="#000"
              fontWeight="lighter"  
              sx={{ fontSize: { xs: '1rem', sm: '1.5rem' } }} 
            >
              {section3Content.subtitle2} 
            </Typography>
          </Grid>
        </Grid>
      </Container>

    
    <Container maxWidth="lg" sx={{ marginTop: '8vh', padding: '50px' }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={7} marginLeft={isMobile ? "0" : "60px"}>
          <Box>
            <Typography
              variant={isMobile ? "h5" : "h2"}
              color="#a64205"
              fontWeight="bold"
              textTransform="uppercase"
              sx={{ marginBottom: '20px' }}
            >
              {section5Content.title}
            </Typography>
            <Typography
              variant={isMobile ? "h5" : "h4"}
              textTransform="uppercase"
              color="#373a3c"
              fontWeight="bold"
              sx={{ marginBottom: '20px' }}
            >
              {section5Content.subtitle1}
            </Typography>
            <Typography
              variant={isMobile ? "h6" : "h5"}
              color="grey"
              fontWeight="lighter"
              sx={{ marginBottom: '20px' }}
            >
              {section5Content.subtitle2}
            </Typography>
            <Typography
              variant={isMobile ? "h6" : "h5"}
              color="red"
              fontWeight="lighter"
            >
              {section5Content.subtitle3}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={4} textAlign="center">
          <Box
            component="img"
            sx={{
              width: '100%', 
              height: 'auto',
              maxWidth: '400px', 
              borderRadius: '8px'
            }}
            src={firstImage}
            alt="Image description"
          />
        </Grid>
      </Grid>
    </Container>



    <Container maxWidth="100%" sx={{ marginTop: '6vh', padding: '40px', backgroundColor: 'rgba(0, 0, 0, 0.3)' }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              backgroundColor: '#333', 
              padding: '30px',
              borderRadius: '8px',
              boxShadow: 3,
              color: '#fff',
            }}
          >
            {/* Cene iznad inputa */}
            <Typography variant="h4" fontWeight="bold" color='red'>
               1260 RSD
            </Typography>
            <Typography variant="h6" fontWeight="light" sx={{ textDecoration: 'line-through', marginBottom: '20px' }}>
              1800 RSD
            </Typography>

            <form onSubmit={handleSubmit} id="naruci" ref={naruciRef}>
              {errors.ime && <p style={{ color: 'red',  }}>{errors.ime}</p>}
              <TextField
                label="Ime"
                variant="outlined"
                fullWidth
                name="ime"
                value={formData.ime}
                onChange={handleChange}
                sx={{ marginBottom: '20px' }}
                error={!!errors.ime}
              />
              {errors.brojTelefona && <p style={{ color: 'red', marginTop: 0  }}>{errors.brojTelefona}</p>}
              <TextField
                label="Broj telefona"
                variant="outlined"
                fullWidth
                name="brojTelefona"
                value={formData.brojTelefona}
                onChange={handleChange}
                sx={{ marginBottom: '20px' }}
                error={!!errors.brojTelefona}
              />
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                name="email"
                value={formData.email}
                onChange={handleChange}
                sx={{ marginBottom: '20px' }}
              />
              <TextField
                label="Adresa"
                variant="outlined"
                fullWidth
                name="adresa"
                value={formData.adresa}
                onChange={handleChange}
                sx={{ marginBottom: '20px' }}
              />

              <Button
                type="submit"
                variant="contained"
                sx={{ backgroundColor: '#a64205', color: '#fff', padding: '10px 20px' }}
                fullWidth
              >
                NARUČI ODMAH
              </Button>
            </form>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <Box
                component="img"
                sx={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '400px', 
                }}
                src={firstImage}
                alt="Descriptive alt text"
              />
            </Grid>
            <Grid item xs={12} >
              <Typography variant={isMobile ? "h5" : "h2"} fontWeight="bold" textAlign="center" color='#373a3c'>
                Tvrd!
              </Typography>
              <Typography variant={isMobile ? "h5" : "h2"} fontWeight="bold" textAlign="center" color='#373a3c'>
                Čvrst!
              </Typography>
              <Typography variant={isMobile ? "h5" : "h2"} fontWeight="bold" textAlign="center" color='#373a3c'>
                Veliki!
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
    

      <Footer content={footerContent}/>


    </>
  );
}

export default App;







